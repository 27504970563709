import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Login({ onLoginSuccess }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    const navigateToSignup = () => {
        navigate('/signup');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const API_URL = 'https://8mqrim6al3.execute-api.us-east-1.amazonaws.com/dev/login';
        // const requestData = {
        //     email: email,
        //     password: password
        // };
        const requestBody = {
            body: JSON.stringify({ email, password })
        };

        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(requestData),
                body: JSON.stringify(requestBody),

            });
            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData);            }
            const message = JSON.parse(responseData.body);
            console.log(message);
            const userID = message.id[0][0].longValue;
            console.log(userID);
            onLoginSuccess(true);
            navigate('/doc');
            localStorage.setItem('userId', userID.toString());
            localStorage.setItem('isLoggedIn', 'true');
            // localStorage.setItem('userId', responseData.id);
            setResponseMessage(message);
        } catch (error) {
            console.error('There was an error!', error);
            setResponseMessage(error.toString());
        }
    };

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Login
                    </Button>
                    {responseMessage && <p>{responseMessage}</p>}
                </Box>
            </Box>
            <Box mt={2}>
                <Typography variant="body2">
                    Don't have an account?
                    <Button color="primary" onClick={navigateToSignup}>
                        Sign Up
                    </Button>
                </Typography>
            </Box>
        </Container>
    );
}

export default Login;
// {"body": "{\"email\": \"test2@test\",\"password\": \"test\",\"firstName\": \"test\",\"lastName\": \"test\",\"title\": \"test\",\"degree\": \"test\",\"licenseNumber\": \"test\",\"specialty\": \"test\"}"}

// {"body": "{\"email\": \"test2@test\",\"password\": \"test\"}"}
