import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Appointments() {

    const apiEndpoint = "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";
    const headers = {
        'Content-Type': 'application/json',
    };
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const patientId = pathParts[pathParts.length - 1];

    const [open, setOpen] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formValues, setFormValues] = useState({
        clinicianId: 'CLIN001',
        patientId: patientId,
        dateValue: '',
        timeValue: '',
        duration: '',
        typeOfMeeting: '',
        appointmentTitle: '',
        participants: '',
        appointmentDescription: '',
        sendEmail: '',
        Status: ''
    });


    const [currentAppointment, setCurrentAppointment] = useState(null);

    const handleEditClick = (appointment) => {
        setCurrentAppointment(appointment);
        setFormValues(appointment);
        setOpen(true);
    };

    const handleDeleteClick = (appointmentId) => {
        handleSaveAppointment('DELETE', appointmentId);
    };


    useEffect(() => {
        const get_req_value = {'clinicianId': 'CLIN001', "task": "getAppointmentData"};
        const queryParams = new URLSearchParams(get_req_value).toString();
        const apiUrl = `${apiEndpoint}?${queryParams}`;
        fetch(apiUrl, {
            method: "GET",
            headers: headers,
        })
            .then(response => response.json())
            .then(data => {
                console.log("Appointment:", data);
                console.log(data.patients_grped);
                // setPatientIds(data.patients_grped);
                setAppointments(data.query_result);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, [isSubmitted]);


    const handleOpenNewDialog = () => {
        setFormValues({
            // appointmentId: null,
            clinicianId: 'CLIN001',
            patientId: patientId,
            dateValue: '',
            timeValue: '',
            duration: '',
            typeOfMeeting: '',
            appointmentTitle: '',
            participants: '',
            appointmentDescription: '',
            sendEmail: '',
            Status: ''
        });
        setCurrentAppointment(null);
        setOpen(true);
    };


    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleSaveAppointment = (typeOfRequest,appointmentId = null) => {
        if (typeOfRequest === 'DELETE') {
            formValues['appointmentId'] = appointmentId;
        }
        const currentDateTime = new Date();
        formValues['uploaded_datetime'] = currentDateTime.toISOString();
        const req_value = {
            "task": "appointmentSetData",
            "query_dict": JSON.stringify(formValues),
            "typeOfRequest": typeOfRequest // 'DELETE', 'INSERT', or 'UPDATE'
        };
        console.log(req_value);
        fetch(apiEndpoint, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(req_value),
        })
            .then((response) => {
                setIsSubmitted(prev => !prev);
                return response.json();
            })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        handleCloseDialog();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpenNewDialog}>Schedule Appointment</Button>

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Schedule an Appointment</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="dateValue"
                        label="Date"
                        type="date"
                        fullWidth
                        variant="standard"
                        name="dateValue"
                        value={formValues.dateValue}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="timeValue"
                        label="Time"
                        type="time"
                        fullWidth
                        variant="standard"
                        name="timeValue"
                        value={formValues.timeValue}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="duration"
                        label="Duration"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="duration"
                        value={formValues.duration}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="typeOfMeeting"
                        label="Type of Meeting"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="typeOfMeeting"
                        value={formValues.typeOfMeeting}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="appointmentTitle"
                        label="Appointment Title"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="appointmentTitle"
                        value={formValues.appointmentTitle}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="participants"
                        label="Participants"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="participants"
                        value={formValues.participants}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="appointmentDescription"
                        label="Appointment Description"
                        multiline
                        rows={4}
                        fullWidth
                        variant="standard"
                        name="appointmentDescription"
                        value={formValues.appointmentDescription}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="sendEmail"
                        label="Send Email Reminder"
                        select
                        fullWidth
                        variant="standard"
                        name="sendEmail"
                        value={formValues.sendEmail}
                        onChange={handleChange}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>
                    <TextField
                        margin="dense"
                        id="Status"
                        label="Status"
                        select
                        fullWidth
                        variant="standard"
                        name="Status"
                        value={formValues.Status}
                        onChange={handleChange}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="Inprogress">In Progress</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Rejected">Rejected</option>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={() => currentAppointment ? handleSaveAppointment('UPDATE') : handleSaveAppointment('INSERT')}>Save</Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="appointment table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Participants</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Email Reminder</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appointments.map((appointment, index) => (
                            <TableRow key={index}>
                                <TableCell>{appointment.dateValue}</TableCell>
                                <TableCell>{appointment.timeValue}</TableCell>
                                <TableCell>{appointment.duration}</TableCell>
                                <TableCell>{appointment.typeOfMeeting}</TableCell>
                                <TableCell>{appointment.appointmentTitle}</TableCell>
                                <TableCell>{appointment.participants}</TableCell>
                                <TableCell>{appointment.appointmentDescription}</TableCell>
                                <TableCell>{appointment.sendEmail}</TableCell>
                                <TableCell>{appointment.Status}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditClick(appointment)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(appointment.appointmentId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default Appointments;
