import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PatientsTask = ({ patientId }) => {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const demoTasks = [
            { id: '1', description: 'Blood Test', provider: 'LabCorp', created: '2024-02-01', performer: 'Dr. Smith', status: 'Completed' },
            { id: '2', description: 'X-Ray', provider: 'Radiology Inc.', created: '2024-02-02', performer: 'Dr. Doe', status: 'Pending' },
            { id: '3', description: 'Physical Exam', provider: 'General Health', created: '2024-02-03', performer: 'Nurse Practitioner Lee', status: 'In Progress' },
        ];

        setTasks(demoTasks);

        /*
        const get_req_value = { 'patientId': patientId, "task": "taskDetailsData" };
        const queryParams = new URLSearchParams(get_req_value);
        const apiEndpoint = 'https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query';
        const headers = {
          'Content-Type': 'application/json',
        };
        const apiUrl = `${apiEndpoint}?${queryParams}`;

        fetch(apiUrl, {
          method: "GET",
          headers: headers,
        })
        .then((response) => {
          console.log(response.status);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          // Assuming your data is an array of tasks
          setTasks(data.tasks);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
        */
    }, [patientId]);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="tasks table">
                <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Provider</TableCell>
                        <TableCell align="right">Created</TableCell>
                        <TableCell align="right">Performer</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasks.map((task) => (
                        <TableRow
                            key={task.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {task.description}
                            </TableCell>
                            <TableCell align="right">{task.provider}</TableCell>
                            <TableCell align="right">{task.created}</TableCell>
                            <TableCell align="right">{task.performer}</TableCell>
                            <TableCell align="right">{task.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PatientsTask;
