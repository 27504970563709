import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Layout from './loginLayout';
function Home() {
    return (
        <Layout>
            <div>
                Welcome to the Home Page!
            </div>
        </Layout>
    );
};

export default Home;
