import React, { useState, useEffect } from 'react';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from "react-router-dom";
import _ from 'lodash';

// import all components pages
import Documentation from './components/documentation';
import PatientsPage from "./components/patientsPage";
import PatientDetail from "./components/patientDetail";
import Home from "./components/home";
import Login from './components/login';
import Signup from "./components/signup";
import withAuth from './components/withAuth';
import PatientsTask from "./components/patientTasks";
import TabPanel from "./components/tabPanel";
export default function App(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const DocumentationWithAuth = withAuth(Documentation, isLoggedIn);
    useEffect(() => {
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        setIsLoggedIn(storedIsLoggedIn);
    }, []);

    const handleLoginSuccess = (status) => {
        setIsLoggedIn(status);
    };

    return (
      <div>
        <Router>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/signup" element={<Signup />} />
              {/*<Route path="/patients" element={<PatientsPage />} WithAuth isLoggedIn={isLoggedIn} />*/}
              {/*<Route path="/patientDetails/:patientId" element={<PatientDetail/>} WithAuth isLoggedIn={isLoggedIn} />*/}
              {/*<Route path="/doc" element={<Documentation WithAuth isLoggedIn={isLoggedIn} />} />*/}

              <Route path="/patients" element={<PatientsPage />} />
              <Route path="/patientDetails/:patientId" element={<PatientDetail/>}  />
              <Route path="/doc" element={<Documentation />} />
              <Route path="/task" element={<PatientsTask />} />
              <Route path="/tab" element={<TabPanel/>} />


          </Routes> 
        </Router>
      </div>
    );
  }
  
