import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';

const Layout = ({ children }) => {
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/login');
    };

    const navigateToSignup = () => {
        navigate('/signup');
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    // justifyContent: 'space-around',
                    padding: '10px',
                }}
            >
                <Button variant="contained" onClick={navigateToLogin}>
                    Log In
                </Button>
                <Button variant="contained" onClick={navigateToSignup}>
                    Sign Up
                </Button>
            </Box>
            <div>{children}</div>
        </Box>
    );
};


export default Layout;

