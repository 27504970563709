import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent,Avatar,IconButton, Typography, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function PatientsPage(props) {
    const [patientsData, setPatientsData] = useState(null);
    const [patientDetail, setPatientDetail] = useState(null);

    const navigate = useNavigate();
    const apiEndpoint = 'https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query';
    const headers = {
        'Content-Type': 'application/json',
    };
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const get_req_value1 = {'clinicianId': 'CLIN001', "task": "allPatientData"};

        // const get_req_value1 = {
        //     'clinicianId': userId ? userId : 'CLIN001', // Use the retrieved userId or default to 'CLIN001'
        //     "task": "allPatientData"
        // };
        const queryParams1 = new URLSearchParams(get_req_value1).toString();
        const apiUrl1 = `${apiEndpoint}?${queryParams1}`;
        console.log(apiUrl1);

        fetch(apiUrl1, {
            method: "GET",
            headers: headers,
        })
            .then(response => response.json())
            .then(data => {
                console.log("HAPPY:", data);
                console.log(data.query_result)
                setPatientsData(data.query_result);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, []);

    const handleCardClick = async (patientId) => {
        navigate('/patientDetails/' + patientId);
    };


    return (
        <Grid container spacing={3}>
            {patientsData ? patientsData.map((patient, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card raised onClick={() => handleCardClick(patient.patientId)}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ }} aria-label="patient">
                                    {patient.patientName[0]}
                                </Avatar>
                            }
                            action={
                                <IconButton aria-label="settings">
                                </IconButton>
                            }
                            title={patient.patientName}
                            subheader={`Patient No: ${patient.patientId} - Gender: ${patient.gender}`}
                        />
                        <CardContent>
                            <Typography variant="body2" component="p">
                                Age: {patient.age}<br/>
                                DOB: {patient.DOB}
                            </Typography>
                            <Typography variant="body2" component="p" style={{ marginTop: '16px' }}>
                                Psychiatrist: {patient.psychiatrist}<br/>
                                Note: {patient.psychiatristNote}<br/>
                                Date: {patient.psychiatristDate}
                            </Typography>
                            <Typography variant="body2" component="p">
                                Primary Therapist: {patient.primaryTherapist}<br/>
                                Note: {patient.primaryTherapistNote}<br/>
                                Date: {patient.primaryTherapistDate}
                            </Typography>
                            <Typography variant="body2" component="p">
                                Group Therapist: {patient.groupTherapist}<br/>
                                Note: {patient.groupTherapistNote}<br/>
                                Date: {patient.groupTherapistDate}
                            </Typography>
                            <Typography variant="body2" component="p">
                                ICD-10 Codes: {patient.icdCodes}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Last updated: {patient.uploaded_datetime}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )) : <div>Loading...</div>}
        </Grid>
    );
}
