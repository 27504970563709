import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function DataVisualization({ data }) {
    // Check data
    if (!data || !data.Histogram || !Array.isArray(data.Histogram)) {
        return <div>No Histogram data available.</div>;
    }
    if (!data || !data.PieChart || !Array.isArray(data.PieChart)) {
        return <div>No pie chart data available.</div>;
    }

    //Histogram
    const transformData = (data) => {
        const groups = [];
        const seriesData = [];

        data.Histogram.forEach((item, index) => {
            const key = Object.keys(item)[0];
            groups.push(key);

            const { labels, counts } = item[key];

            labels.forEach((label, labelIndex) => {
                if (seriesData[labelIndex] === undefined) {
                    seriesData[labelIndex] = { label: label, data: [] };
                }
                seriesData[labelIndex].data.push(counts[labelIndex]);
            });
        });

        return {
            xAxis: [{ scaleType: 'band', data: groups }],
            series: seriesData,
        };
    };

    const { xAxis, series } = transformData(data);


    //PieChart
    const pieParams = { height: 200, width:600};

    const transformPieData = (pieData) => {
        return pieData.map((category) => {
            const categoryName = Object.keys(category)[0];
            const { labels, counts } = category[categoryName];
            const transformedData = labels.map((label, index) => ({
                label: labels[index] || 'Unknown',
                value: counts[index],
            })).filter(item => item.label !== 'Unknown');

            return {
                name: categoryName,
                data: transformedData,
            };
        });
    };
    const pieChartsData = transformPieData(data.PieChart);


    return (
        <div style={{ justifyContent: 'center'}}>
            <BarChart
                xAxis={xAxis}
                series={series}
                width={500}
                height={300}
            />
            <Stack direction="row" width="100%" textAlign="center" spacing={2} flexWrap="wrap">
                {pieChartsData.map((pie, index) => (
                    <Box key={index} flexGrow={1} minWidth="300px">
                        <Typography>{pie.name.charAt(0).toUpperCase() + pie.name.slice(1)}</Typography>
                        <PieChart
                            colors={['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']}
                            series={[{ data: pie.data }]}
                            {...pieParams}
                        />
                    </Box>
                ))}
            </Stack>
        </div>
    );
}

export default DataVisualization;