import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Container } from '@mui/material';
import PatientTasks from "./patientTasks";
import DBTTrackerTab from "./dBTTrackerTab";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function CustomTabs() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Data Visualization" {...a11yProps(0)} />
                        <Tab label="Tasks" {...a11yProps(1)} />
                        <Tab label="DBT Tracker" {...a11yProps(2)} />

                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Typography>Data Visualization Content</Typography>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {/* Tasks*/}
                    <Typography>Tasks Content</Typography>
                    {<PatientTasks data={1} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {/* dbt*/}
                    <Typography>DBT Tracker</Typography>
                    {<DBTTrackerTab data={2} />}
                </TabPanel>
            </Box>
        </Container>
    );
}
