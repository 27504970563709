import React, {useState, useRef, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


// Define a CollapsibleSection component that manages its open/closed state
const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the section's open/closed state
  const toggleOpen = () => setIsOpen(!isOpen);

  const sectionClass = `collapsible-section ${!isOpen ? "collapsed" : ""}`;

  return (
    <div className={sectionClass}>
      <button type="button" onClick={toggleOpen} className="collapsible-title">
        {title}
        <span className="collapse-button"></span>
      </button>
      {isOpen && <div className="content">{children}</div>}
    </div>
  );
};

// Main Documentation component that will render the form with collapsible sections
export default function Documentation(props) {
  const formRef = useRef(null);

  //handle api calls
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [patientIds, setPatientIds] = useState([]);
  const [patientId, setPatientId] = useState('PAT001');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setPatientId(event.target.value);
    console.log(patientId)
  };

  const apiEndpoint = "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";

  const headers = {
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const get_req_value1 = {'clinicianId': 'CLIN001', "task": "allPatientData"};
    const queryParams1 = new URLSearchParams(get_req_value1).toString();
    const apiUrl1 = `${apiEndpoint}?${queryParams1}`;
    console.log(apiUrl1);

    fetch(apiUrl1, {
      method: "GET",
      headers: headers,
    })
        .then(response => response.json())
        .then(data => {
          console.log("HAPPY:", data);
          console.log(data.patients_grped);
          setPatientIds(data.patients_grped);
        })
        .catch(error => {
          console.error("Error:", error);
        });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);
    const formValues = {};

    for (let [name, value] of formData.entries()) {
      if (formData.getAll(name).length > 1) {
        formValues[name] = JSON.stringify(formData.getAll(name));
      } else if (value.trim() !== "") {
        formValues[name] = value;
      }
    }

    // formValues['patientId'] = 'PAT001';
    formValues['patientId'] = String(patientId);
    const currentDateTime = new Date();
    formValues['uploaded_datetime'] = currentDateTime.toISOString();
    console.log("DUSTIN:",formValues);

    const req_value =
        {
          "task": "patientSubmitFormData",
          "query_dict": JSON.stringify(formValues),
          "patientId": patientId
  }

    fetch(apiEndpoint, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(req_value),
    })
        .then((response) => {
          console.log(response.status);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setIsSubmitted(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    navigate('/patients');

      // const get_req_value = {'patientId' : 'PAT001',"task": "patientSubmitFormData"};
      //
      // const queryParams = new URLSearchParams(get_req_value);
      //
      // const apiUrl = `${apiEndpoint}?${queryParams}`;
      //
      // console.log(apiUrl);
      //
      // fetch(apiUrl, {
      //   method: "GET",
      //   headers: headers,
      // })
      //   .then((response) => {
      //     console.log(response.status);
      //     return response.json();
      //   })
      //   .then((data) => {
      //     console.log(data);
      //     const parsedData = JSON.parse(data.plot_query_result.replace(/None/g, "null").replace(/'/g, '"'));
      //     console.log(parsedData);
      //     setApiResponse(parsedData);
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });


  };



  const camelCaseToTitle = (text) => {
    return text
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const checkboxCategories = {
    appearance: ["Neat", "Disheveled", "Inappropriate", "Other", "Bizarre"],
    speech: [
      "Clear & Coherent",
      "Tangential",
      "Pressured",
      "Rapid",
      "Slow",
      "Loud",
      "Quiet",
      "Talkative",
      "Other",
      "Impoverished",
    ],
    eyeContact: ["Normal", "Intense", "Avoidant", "Other"],
    moterActivity: ["Normal", "Restless", "Tics", "Slowed", "Other"],
    affect: [
      "Full Range",
      "Congruent",
      "Flat",
      "Labile",
      "Blunted",
      "Restricted",
      "Incongruent",
      "Other",
    ],
    mood: [
      "Euthymic",
      "Anxious",
      "Angry",
      "Depressed",
      "Euphoric",
      "Irritable",
      "Other",
    ],
    orientation: ["Person", "Place", "Time", "Impaired"],
    memory: ["Intact", "Short Term Impairment", "Long Term Impairment"],
    attention: ["Intact", "Distracted", "Other"],
    perception: [
      "Hallucinations (A)",
      "Hallucinations (V)",
      "Derealization",
      "Depersonalization",
      "Other",
    ],
    thoughtProcess: [
      "Goal Directed",
      "Disorganized",
      "Perseveration",
      "Loose Association",
      "Circumstantial",
      "Logical",
      "Thought Blocking",
      "Flight of Ideas",
      "Magical Thinking",
      "Other",
    ],
    thoughtContent: [
      "Normal",
      "SI",
      "HI",
      "Delusions",
      "Obsessions",
      "Phobias",
      "Paranoia",
      "Ideas of Reference",
      "Ruminations",
      "Other",
    ],
    behavior: [
      "Cooperative",
      "Guarded",
      "Hyperactive",
      "Agitated",
      "Paranoid",
      "Aggressive",
      "Bizarre",
      "Withdrawn",
      "Other",
    ],
    insight: ["Good", "Fair", "Poor"],
    judgement: ["Good", "Fair", "Poor"],
  };

  // Render the form with multiple CollapsibleSection components
  return (

      <div className="container">
        {!isSubmitted ? (
          <form ref={formRef} className="clinical-doc" onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <InputLabel id="patient-selector-label">Patient ID</InputLabel>
              <Select
                  labelId="patient-selector-label"
                  id="patient-selector"
                  value={patientId}
                  label="Patient ID"
                  onChange={handleChange}
              >
                {patientIds.map((id) => (
                    <MenuItem key={id} value={id}>{id}</MenuItem>
                ))}
              </Select>
            </FormControl>
      <div className="radio-group">
        <p>Was the patient located at home:</p>
        <input id="locateHomeYes" type="radio" name="locateHome" value="yes" />
        <label htmlFor="locateHomeYes">Yes</label>
        <input id="locateHomeNo" type="radio" name="locateHome" value="no" />
        <label htmlFor="locateHomeNo">No</label>
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          placeholder="Where were they located?"
          name="locateHomeText"
        ></textarea>
      </div>

      <CollapsibleSection title="Safety Assessment">
        {/* Place for the safety assessment description*/}
        <div className="radio-group">
          <p>Does patient endorse SI/HI/AVH?</p>
          <input
            id="safetyAssessmentYes"
            type="radio"
            name="safetyAssessment"
            value="yes"
          />
          <label htmlFor="safetyAssessmentYes">Yes</label>
          <input
            id="safetyAssessmentNo"
            type="radio"
            name="safetyAssessment"
            value="no"
          />
          <label htmlFor="safetyAssessmentNo">No</label>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Medications">
        {/* Place for the medication table as shown on UI design*/}
        <div className="form-group">
          <label htmlFor="responseToMedication">
            Response to medication/adherence:
          </label>
          <textarea
            id="responseToMedication"
            className="form-control"
            name="responseToMedicationText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="medicationConcerns">
            Medication concerns for prescribing provider:
          </label>
          <textarea
            id="medicationConcerns"
            className="form-control"
            name="medicationConcernsText"
          ></textarea>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Clinical Overview">
        {/* Place for the description of the clinical overview as shown on UI design*/}
        <div className="diagnosis-list">
          <p>Diagnosis:</p>
          {/* Place for the diagnosis as shown on UI design*/}
        </div>
        <div className="form-group">
          <label htmlFor="currentSymptoms">Current Symptoms:</label>
          <textarea
            id="currentSymptoms"
            className="form-control"
            name="currentSymptomsText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="medicationConcernsOverview">
            Medication concerns for prescribing provider:
          </label>
          <textarea
            id="medicationConcernsOverview"
            className="form-control"
            name="medicationConcernsOverviewText"
          ></textarea>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Mental Status Exam">
        {/* Place for the description of the Mental Status Exam as shown on UI design*/}
        <div className="mental-status-exam-container">
          {Object.keys(checkboxCategories).map((category) => (
            <div key={category} className="row">
              <label>{camelCaseToTitle(category)}</label>
              <div className="checkbox-group">
                {checkboxCategories[category].map((option) => (
                  <label key={option}>
                    <input type="checkbox" name={category} value={option} />
                    {option}
                  </label>
                ))}
              </div>
              <textarea
                className="form-control"
                name={`${category}Comment`}
                placeholder={`Comment on ${category}`}
              />
            </div>
          ))}
        </div>
      </CollapsibleSection>

      <CollapsibleSection title="Session Note">
        <div className="radio-group">
          <label>Would you like to use Session Note Template?</label>
          <label>
            <input type="radio" name="useTemplate" value="yes" />
            Yes
          </label>
          <label>
            <input type="radio" name="useTemplate" value="no" />
            No
          </label>
        </div>
        {/* Place for the psychotherapy note as shown on UI design*/}
        <div className="form-group">
          <label htmlFor="psychotherapyNote">
             Your Psychotherapy Note:
          </label>
          <textarea
            id="psychotherapyNote"
            className="form-control"
            name="psychotherapyNote"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="patientReports">
            Patient Reports (Subjective Information):
          </label>
          <textarea
            id="patientReports"
            className="form-control"
            name="patientReportsText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="clinicalObservations">
            Clinical Observations (Objective Information):
          </label>
          <textarea
            id="clinicalObservations"
            className="form-control"
            name="clinicalObservationsText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="progressUpdates">
            Progress/Updates Since Last Session:
          </label>
          <textarea
            id="progressUpdates"
            className="form-control"
            name="progressUpdatesText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="clinicalInterventions">
            Clinical Interventions Used:
          </label>
          <textarea
            id="clinicalInterventions"
            className="form-control"
            name="clinicalInterventionsText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="copingSkills">
            Coping Skills Reviewed or Introduced:
          </label>
          <textarea
            id="copingSkills"
            className="form-control"
            name="copingSkillsText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="assessmentProgress">Assessment of Progress:</label>
          <textarea
            id="assessmentProgress"
            className="form-control"
            name="assessmentProgressText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="plan">Plan:</label>
          <textarea
            id="plan"
            className="form-control"
            name="planText"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="cptCodes">CPT Codes:</label>
          <textarea
            id="cptCodes"
            className="form-control"
            name="cptCodesText"
          ></textarea>
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" name="acknowledgement" />I acknowledge that
            the above information is correct and has been reviewed prior to
            submitting.
          </label>
        </div>
      </CollapsibleSection>

      <div className="form-actions">
        <button type="button" className="cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </div>
    </form>
        ) : (
            // apiResponse && <DataTable data={apiResponse.data} />
            <>
            </>
        )}
      </div>
  );
}