import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, Tab, Tabs, Button, AccordionSummary, AccordionDetails, Box, Container, Card, CardContent, Typography, Grid, List, ListItem, ListItemText, Divider, Chip } from '@mui/material';
import DataVisualization from './dataVisualization';
import { useNavigate } from 'react-router-dom';
import PatientTasks from "./patientTasks";
import DBTTrackerTab from "./dBTTrackerTab";
import Appointments from "./appointments";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function PatientDetail(props) {
    const [patientDetail, setPatientDetail] = useState(null);
    const [plotResult, setPlotResult] = useState(null);

    const [topNotes, setTopNotes] = useState([]);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const apiEndpoint = 'https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query';
    const headers = {
        'Content-Type': 'application/json',
    };
    const navigate = useNavigate();
    const { patientId } = useParams();

    const redirectToDoc = () => {
        navigate('/doc');
    };
    useEffect(() => {
        const get_req_value2 = {'clinicianId': 'CLIN001', 'patientId': patientId, "task": "specificPatientData"};
        const queryParams2 = new URLSearchParams(get_req_value2).toString();
        const apiUrl2 = `${apiEndpoint}?${queryParams2}`;
        console.log(apiUrl2);

        fetch(apiUrl2, {
            method: "GET",
            headers: headers,
        })
            .then(response => response.json())
            .then(data => {
                console.log("SAD:", data);
                if (data.clinical_response_result && data.clinical_response_result.length > 0) {
                    const firstFiveNotes = data.clinical_response_result.slice(0, 5);
                    setTopNotes(firstFiveNotes);
                }
                setPatientDetail(data.details_query_result[0]);
            })
            .catch(error => {
                console.error("Error:", error);
            });

        const get_req_value = {'patientId' : patientId,"task": "patientSubmitFormData"};

        const queryParams = new URLSearchParams(get_req_value);

        const apiUrl = `${apiEndpoint}?${queryParams}`;
        fetch(apiUrl, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                console.log(response.status);
                return response.json();
            })
            .then((data) => {
                console.log(data);
                const parsedData = JSON.parse(data.plot_query_result.replace(/None/g, "null").replace(/'/g, '"'));
                console.log("parsed", parsedData);
                setPlotResult(parsedData)
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }, [patientId]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', height: '100vh' }}>
            <Container maxWidth="md">
                <Card elevation={3}>
                    {patientDetail ? (
                        <CardContent>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {patientDetail.patientName}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Patient No: {patientDetail.patientId}</Typography>
                                    <Typography>Gender: {patientDetail.gender}</Typography>
                                    <Typography>Age: {patientDetail.age}</Typography>
                                    <Typography>DOB: {patientDetail.DOB}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider light />
                                </Grid>
                                {['psychiatrist', 'primaryTherapist', 'groupTherapist'].map((role, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Typography variant="body2">{patientDetail[`${role}`]}</Typography>
                                        <Typography variant="body2">Note: {patientDetail[`${role}Note`]}</Typography>
                                        <Typography variant="body2">Date: {patientDetail[`${role}Date`]}</Typography>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <Typography variant="body2">ICD-10 Codes: {patientDetail.icdCodes}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider light />
                            </Grid>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6" component="h3" gutterBottom>
                                Notes
                            </Typography>
                            <Button variant="contained" color="primary" onClick={redirectToDoc}>
                                Create New Form
                            </Button>
                            </Box>

                            {topNotes.length > 0 ? (
                                <List>
                                    {topNotes.map((note, index) => (
                                        <ListItem key={index} style={{ width: '100%', marginBottom: '8px' }}>
                                            <Accordion variant="outlined" style={{ width: '100%' }}>
                                                <AccordionSummary >
                                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Patient Note - {index + 1}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div style={{ width: '100%' }}>{note.psychotherapyNote && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Psychotherapy Note</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.psychotherapyNote}</Typography>
                                                        </>
                                                    )}
                                                    {note.patientReportsText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Patient Reports</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.patientReportsText}</Typography>
                                                        </>
                                                    )}
                                                    {note.clinicalObservationsText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Clinical Observations</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.clinicalObservationsText}</Typography>
                                                        </>
                                                    )}
                                                    {note.progressUpdatesText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Progress Updates</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.progressUpdatesText}</Typography>
                                                        </>
                                                    )}
                                                    {note.clinicalInterventionsText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Clinical Interventions</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.clinicalInterventionsText}</Typography>
                                                        </>
                                                    )}
                                                    {note.copingSkillsText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Coping Skills</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.copingSkillsText}</Typography>
                                                        </>
                                                    )}
                                                    {note.assessmentProgressText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Assessment Progress</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.assessmentProgressText}</Typography>
                                                        </>
                                                    )}
                                                    {note.planText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Plan</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.planText}</Typography>
                                                        </>
                                                    )}
                                                    {note.cptCodesText && (
                                                        <>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>CPT Codes</Typography>
                                                            <Typography variant="body2" gutterBottom>{note.cptCodesText}</Typography>
                                                        </>
                                                    )}
                                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>Updated Time</Typography>
                                                    <Typography variant="body3" gutterBottom>{note.uploaded_datetime}</Typography>
                                                    </div>

                                                </AccordionDetails>
                                            </Accordion>
                                        </ListItem>
                                        ))}
                                </List>
                            ) : (
                                <Typography>No notes available.</Typography>
                            )}
                            <Grid item xs={12}>
                                <Divider light />
                            </Grid>
                            {/*<Container>*/}
                            {/*    <Box sx={{ width: '100%' }}>*/}
                            {/*        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                            {/*            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">*/}
                            {/*                <Tab label="Data Visualization" {...a11yProps(0)} />*/}
                            {/*                <Tab label="Tasks" {...a11yProps(1)} />*/}
                            {/*            </Tabs>*/}
                            {/*        </Box>*/}
                            {/*        <TabPanel value={value} index={0}>*/}
                            {/*            <Typography>Data Visualization Content</Typography>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel value={value} index={1}>*/}
                            {/*            /!* Tasks*!/*/}
                            {/*            <Typography>Tasks Content</Typography>*/}
                            {/*            {<PatientTasks data={1} />}*/}
                            {/*        </TabPanel>*/}
                            {/*    </Box>*/}
                            {/*</Container>*/}
                            {/*<Typography variant="h6" component="h3" gutterBottom>*/}
                            {/*    Data Visualization*/}
                            {/*</Typography>*/}
                            {/*{plotResult && <DataVisualization data={plotResult} />}*/}
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Typography variant="h5" component="h2">Loading Patient {patientId}...</Typography>
                        </CardContent>
                    )}
                </Card>
            </Container>
            <Container>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Data Visualization" {...a11yProps(0)} />
                            <Tab label="Tasks" {...a11yProps(1)} />
                            <Tab label="DBT Tracker" {...a11yProps(2)} />
                            <Tab label="Appointments" {...a11yProps(3)} />

                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {plotResult && <DataVisualization data={plotResult} />}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* Tasks*/}
                        <Typography>Tasks Content</Typography>
                        {<PatientTasks data={1} />}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {/* dbt*/}
                        <Typography>DBT Tracker</Typography>
                        {<DBTTrackerTab data={2} />}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Typography>Appointments</Typography>
                        {<Appointments data={3} />}
                    </TabPanel>

                </Box>
            </Container>
        </Box>
    );
};