import React, { useState } from 'react';
import { InputLabel, Select, MenuItem, FormControl, Box, Typography, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ShowChart as LineChartIcon } from '@mui/icons-material';
import { LineChart } from '@mui/x-charts/LineChart';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DBTTrackerTab = () => {
    const [value, setValue] = useState(0);

    // Demo data
    const demoData = [
        {
        "date": "Jul 1, 2023",
        "behaviors": {
            "Sleep": 8,
            "Nightmares": "No",
            "Exercise": "Yes, 3",
            "Meditation": 20, // minutes
            "Relaxation": 50,
            "Social Interaction": 4,
            "Reached out for Support": "Yes, 2",
            "Joyful Activity": "Yes, 1",
            "Medication Compliance": "Yes, 3",
            "Stress": 3,
            "Physical Pain": 1
        },
        "urges": {
            "Suicidal Ideation": "No",
            "Self Harm": "Yes",
            "Skin Picking/Pulling Hair": "No",
            "Overspending": "No",
            "Procrastination": 4,
            "Disordered Eating Behaviors": "No",
            "Negative Self Talk": "Yes",
            "Pushing Boundaries": "No",
            "Substance Use": "Yes",
            "Risky Behavior": "No"
        },
        "emotions": {
            "Anxiety": 5, // anxiety level
            "Fear": 2,
            "Anger": 1,
            "Shame": 3,
            "Sadness": 2,
            "Self Acceptance": 5,
            "Resilience": 1,
            "Compassion": 2,
            "Joy": 1
        }
    }, {
            "date": "Jul 2, 2023",
            "behaviors": {
                "Sleep": 8,
                "Nightmares": "No",
                "Exercise": "Yes",
                "Meditation": 20, // minutes
                "Relaxation": 50,
                "Social Interaction": 4,
                "Reached out for Support": "Yes",
                "Joyful Activity": "Yes",
                "Medication Compliance": "Yes",
                "Stress": 3,
                "Physical Pain": 1
            },
            "urges": {
                "Suicidal Ideation": "No",
                "Self Harm": "Yes",
                "Skin Picking/Pulling Hair": "No",
                "Overspending": "No",
                "Procrastination": 4,
                "Disordered Eating Behaviors": "No",
                "Negative Self Talk": "Yes",
                "Pushing Boundaries": "No",
                "Substance Use": "Yes",
                "Risky Behavior": "No"
            },
            "emotions": {
                "Anxiety": 1, // anxiety level
                "Fear": 2,
                "Anger": 3,
                "Shame": 0,
                "Sadness": 4,
                "Self Acceptance": 5,
                "Resilience": 1,
                "Compassion": 2,
                "Joy": 3
            }
        }
    ];
    const timeframes = {
        '1day': 1,
        '7day': 7,
        '1month': 30
    };
    const [timeframe, setTimeframe] = useState('7day');
    const [filteredData, setFilteredData] = useState(demoData);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeTimeframe = (event) => {
        const newTimeframe = event.target.value;
        setTimeframe(newTimeframe);

        const daysToDisplay = timeframes[newTimeframe];
        const today = new Date();
        const filtered = demoData.filter((entry) => {
            const entryDate = new Date(entry.date);
            const diffTime = Math.abs(today - entryDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays <= daysToDisplay;
        });

        setFilteredData(filtered);
    };


    return (
        <Box sx={{ width: '100%' }}>
            <FormControl fullWidth>
                <InputLabel id="timeframe-select-label">Timeframe</InputLabel>
                <Select
                    labelId="timeframe-select-label"
                    id="timeframe-select"
                    value={timeframe}
                    label="Timeframe"
                    onChange={handleChangeTimeframe}
                >
                    <MenuItem value="1day">1 Day</MenuItem>
                    <MenuItem value="7day">7 Days</MenuItem>
                    <MenuItem value="1month">1 Month</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="DBT Tracker Tabs">
                    <Tab label="Table View" {...a11yProps(0)} />
                    <Tab label="Chart View" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="DBT Diary Card table - Transposed View">
                        <TableHead>
                            <TableRow>
                                <TableCell>Category / Date</TableCell>
                                {demoData.map((dayData, index) => (
                                    <TableCell key={index} align="center">{dayData.date}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Map over Behaviors, Urges, and Emotions */}
                            {['behaviors', 'urges', 'emotions'].flatMap(category => (
                                Object.keys(demoData[0][category]).map((key, index) => (
                                    <TableRow key={`${category}-${key}`}>
                                        <TableCell component="th" scope="row">
                                            {key}
                                        </TableCell>
                                        {demoData.map((dayData) => (
                                            <TableCell align="center" key={`${dayData.date}-${key}`}>
                                                {dayData[category][key]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Box sx={{ height: 300 }}>
                    <LineChart
                        width={800}
                        height={500}
                        series={Object.keys(demoData[0].emotions).map(emotion => {
                            return {
                                label: emotion,
                                data: demoData.map(dayData => dayData.emotions[emotion])
                            };
                        })}
                        xAxis={[{ scaleType: 'point', data: demoData.map(entry => entry.date) }]}
                    />
                </Box>
            </TabPanel>
        </Box>
    );
};


export default DBTTrackerTab;
