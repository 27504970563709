import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box,  FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [title, setTitle] = useState('');
    const [degree, setDegree] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const [isOther, setIsOther] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const value = event.target.value;
        setSpecialty(value);
        setIsOther(value === 'Other');
    };
    const navigateToLogin = () => {
        navigate('/login');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        const API_URL = 'https://8mqrim6al3.execute-api.us-east-1.amazonaws.com/dev/signup';
        const requestBody = {
            body: JSON.stringify({ email, password, firstName, lastName, title, degree, licenseNumber, specialty })
        };
        let message = null;
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData);
            }
            try {
                const message = responseData ? JSON.parse(responseData.body) : null;
            } catch (error) {
                console.error('There was an error parsing the JSON:', error);
            }
            // const message = JSON.parse(responseData.body);
            setResponseMessage(message);
        } catch (error) {
            console.error('There was an error!', error);
            setResponseMessage(error.toString());
        }
    };

    return (
        <Container maxWidth="xs">
            <Box mt={2}>
                <Typography variant="body2">
                    Already have an account?
                    <Button color="primary" onClick={navigateToLogin}>
                        Log In
                    </Button>
                </Typography>
            </Box>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Signup
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="firstName"
                        label="FirstName"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="lastName"
                        label="LastName"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="title"
                        label="Title"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="degree"
                        label="Degree"
                        id="degree"
                        value={degree}
                        onChange={(e) => setDegree(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="licenseNumber"
                        label="LicenseNumber"
                        id="licenseNumber"
                        value={licenseNumber}
                        onChange={(e) => setLicenseNumber(e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="specialty-label">Specialty</InputLabel>
                        <Select
                            labelId="specialty-label"
                            id="specialty"
                            value={specialty}
                            label="Specialty"
                            onChange={handleChange}
                            required
                            fullWidth
                        >
                            <MenuItem value="Therapist">Therapist</MenuItem>
                            <MenuItem value="Psychiatrist">Psychiatrist</MenuItem>
                            <MenuItem value="Dietitian">Dietitian</MenuItem>
                            <MenuItem value="Nurse">Nurse</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        {isOther && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="specialtyOther"
                                label="Specify Other Specialty"
                                id="specialtyOther"
                                value={specialty === 'Other' ? '' : specialty}
                                onChange={(e) => setSpecialty(e.target.value)}
                            />
                        )}
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Signup
                    </Button>
                    {responseMessage && <p>{responseMessage}</p>}
                </Box>
            </Box>
        </Container>
    );
}

export default Signup;
