// withAuth.js
import React from 'react';
import { Navigate } from 'react-router-dom';
const withAuth = (WrappedComponent, isLoggedIn) => {
    return props => {
        if (!isLoggedIn) {
            return <Navigate to="/login" />;
        }
        return <WrappedComponent {...props} />;
    };
};

export default withAuth;
